<template>

    <div class="d-flex">

        <img :src="currentLanguage.image" width="35px" class="mr-4">

        <HeaderMenu v-if="section == 'Header'" :language="language" />

        <FooterMenu v-else :language="language" />

    </div>

</template>

<script>
import { computed, inject } from "@vue/composition-api";
import HeaderMenu from "@/components/menus/Header";
import FooterMenu from "@/components/menus/Footer";

export default {
    
    components: {
        HeaderMenu,
        FooterMenu
    },

    props: [
        'language',
        'section'
    ],

    setup(props) {

        const app = inject('app');

        const currentLanguage = computed(() => {

            return app.state.availableLanguages.find((lang) => {
                if(lang.key == props.language){ return true ; }
            });

        });

        return {
            currentLanguage
        }
        
    },
}
</script>