<template>
    
    <v-container>

        <div class="px-2">
                
            <h1 class="mt-8">{{ $t("Menu's") }}</h1>

            <v-breadcrumbs
                :items="breadcrumbs"
                large class="pl-0 pt-2"
            ></v-breadcrumbs>
        
        </div>

        <v-card class="pa-4 ma-2 d-flex align-center">
            
            <v-icon left>mdi-filter-variant</v-icon>

            <v-spacer></v-spacer>

            <v-select
                :label="$t('Section')"
                outlined hide-details dense
                style="max-width: 200px;"
                :items="['Header', 'Footer']"
                v-model="menu"
            ></v-select>
            
            <v-select
                :items="app.state.availableLanguages"
                :label="$t('Select language')"
                outlined hide-details
                dense style="max-width:200px;"
                class="ml-2"
                v-model="pages.state.selectedLanguage"
                item-value="key"
                item-text="name"
                @change="get()"
            ></v-select>

        </v-card>
        
        <div
        v-for="language in currentWebsite.state.data.selected_languages"
        :key="language">

            <v-card class="ma-2 pa-4"
            v-if="pages.state.selectedLanguage == language">

                <MenuSelect :language="language" :section="menu" />

            </v-card>
            
        </div>
        
    </v-container>

</template>

<script>
    
import { inject, ref } from '@vue/composition-api' ;
import MenuSelect from "@/components/menus/MenuSelect" ;
import pages from "@/store/pages/pages" ;

export default {
    
    components: {
        MenuSelect
    },

    provide: {
        pages
    },

    setup(props, ctx){
    
        const app = inject('app') ;
        const currentUser = inject('currentUser') ;
        const currentWebsite = inject('currentWebsite') ;

        const menu = ref('Header');

        const breadcrumbs = ref([
            {
                text: ctx.root.$t('Dashboard'),
                disabled: false,
                exact: true,
                to: '/dashboard',
            },
            {
                text: ctx.root.$t("Menu's"),
                disabled: false,
                exact: true,
                to: '/menus',
            },
        ]) ;

        if(pages.state.selectedLanguage == null){
            pages.state.selectedLanguage = currentWebsite.state.data.primary_language ;
        }

        return {
            app,
            currentWebsite,
            currentUser,
            breadcrumbs,
            pages,
            menu
        }
    
    },

};

</script>