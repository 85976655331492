<template>
    
    <div class="d-flex align-center">

        <Draggable
            handle=".menuDragIcon"
            class="d-flex align-center flex-wrap" 
            v-model="currentWebsite.state.data.menu_header[language]"
            group="menus" 
            @start="drag=true" 
            @end="update"
        >

            <v-card
                class="pa-4 pr-2 d-flex align-center ma-1"
                outlined
                v-for="menu in currentWebsite.state.data.menu_header[language]" :key="menu.id"
            >

                <v-icon left class="menuDragIcon" style="cursor: pointer;">mdi-drag-vertical</v-icon>

                <!-- <div>{{ menu.name }}</div> -->
                <div>
                    <v-text-field
                        outlined
                        dense
                        hide-details
                        :value="menu.name"
                        v-model="menu.name"
                        @change="update"
                        style="width: fit-content;"
                    ></v-text-field>
                </div>
                
                <v-btn icon @click="deleteMenuItem(menu)">
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>

            </v-card>

        </Draggable>

        <v-btn
            :color="app.state.style.primary"
            icon
            @click="addPopup()"
        >
            <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-dialog
            v-model="dialog"
            width="500"
        >

            <v-card>

                <v-card-title>
                    {{ $t('Add') }}
                </v-card-title>

                <v-divider></v-divider>

                <div class="pa-4"><SelectPage @selected="setSelectedPage" /></div>

                <!-- {{ selectedPage }} -->

                <v-divider></v-divider>

                <div class="pa-4">
                    <v-btn
                        :color="app.state.style.primary"
                        dark @click="addPage()"
                        depressed
                    >
                        {{ $t('Add') }}
                    </v-btn>

                    <v-btn
                        :color="app.state.style.primary"
                        outlined
                        class="ml-2"
                        @click="dialog=false"
                    >
                        {{ $t('Cancel') }}
                    </v-btn>

                </div>

            </v-card>

        </v-dialog>

    </div>

</template>

<script>
import Vue from 'vue'

import { inject, ref, computed } from '@vue/composition-api' ;
import Draggable from 'vuedraggable'
import { uuid } from 'vue-uuid'
import SelectPage from "@/components/pages/Select" ;
import pages from "@/store/pages/pages" ;

export default {

    props: ['language'],

    components: {
        Draggable,
        SelectPage
    },

    provide: {
        pages
    },
    
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentUser = inject('currentUser') ;
        const currentWebsite = inject('currentWebsite') ;
        const drag = ref(false) ;
        const dialog = ref(false) ;
        const selectedMenu = ref(null) ;
        const selectedPage = ref(null) ;
        
        if(currentWebsite?.state?.data?.menu_header == null){
            Vue.set(currentWebsite.state.data, 'menu_header', {});
        }

        if(currentWebsite?.state?.data?.menu_header[props.language] == null){
            currentWebsite.state.data.menu_header[props.language] = [] ;
        }
            
        const update = () => {

            let data = { 
                menu_header: {
                    [props.language]: currentWebsite.state.data.menu_header[props.language]
                }
            } ;
            
            currentWebsite.update(data)
            .then(() => {
                app.setSuccess(ctx.root.$t('Saved'));
            })
            .catch((error) => {
                app.setError(error);
            });

        }

        const addPopup = () => {

            selectedMenu.value = null ;
            dialog.value = true ;

        }

        const add = (menuItem) => {

            selectedMenu.value = menuItem ;
            currentWebsite.state.data.menu_header[props.language].push(menuItem);
            dialog.value = false ;

            update();

        }

        const currentLanguage = computed(() => {

            return app.state.availableLanguages.find((lang) => {
                if(lang.key == props.language){ return true ; }
            });

        });

        const addPage = async () => {

            if(selectedPage.value == null){
                app.setSuccess(ctx.root.$t('Please select a page'));
                return;
            }

            let to = await pages.getUri(selectedPage.value) ;

            let newMenu = {
                id: uuid.v4(),
                page_id: selectedPage.value.id,
                name: selectedPage.value.data().name,
                path: to
            } ;

            add(newMenu) ;

        }

        const setSelectedPage = (page) => {
            selectedPage.value = page ;
        }
        
        const deleteMenuItem = (menu) => {

            let index = currentWebsite.state.data.menu_header[props.language].findIndex((x) => x.id == menu.id);

            currentWebsite.state.data.menu_header[props.language].splice(index, 1);

            update();
            
        }

        return {
            app,
            currentWebsite,
            currentUser,
            drag,
            update,
            currentLanguage,
            dialog,
            selectedMenu,
            addPage,
            setSelectedPage,
            selectedPage,
            addPopup,
            deleteMenuItem
        }
    
    },

};

</script>